import React, { useEffect, useRef, useState } from 'react'

import { useMediaQuery } from "react-responsive";
import Slider from 'react-slick';
import Carousel from '../../SlideCarousel/Carousel';

function ProductCard(srcs, alts) {

    const carouselItems = [
        {
            id: 0,
            imageSrc: "https://picsum.photos/501/501",
        },
        {
            id: 1,
            imageSrc: "https://picsum.photos/501/502",
        },
        {
            id: 2,
            imageSrc: "https://picsum.photos/501/503",
        },
        {
            id: 3,
            imageSrc: "https://picsum.photos/501/504",
        },
        {
            id: 4,
            imageSrc: "https://picsum.photos/501/505",
        },
    ];


    const sliderRef = useRef(null);

    const plays = () => {
        sliderRef.current.slickPause();
    }

    const pause = () => {
        sliderRef.current.slickPlay();
    }

    const settings = {
        dots: false,
        arrows: false,
        speed: 3000,
        autoplaySpeed: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>

            <div className='w-24 flex overflow-hidden justify-center'>
                <section  className='w-60'>
                    {/* <div className="card-conatiner"> */}
                        <Slider {...settings} ref={sliderRef}>
                            {carouselItems.map((i, index) => (
                                <img onMouseEnter={pause} onMouseLeave={plays}
                                style={{
                                    // transitionDelay: "2s"
                                }}
                                    key={index}
                                    src={i.imageSrc}
                                    alt="Display"
                                />
                            ))}
                        </Slider>
                    {/* </div> */}
                </section>
            </div>
        </>
    )
}

export default ProductCard