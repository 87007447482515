import { createSlice } from "@reduxjs/toolkit";
const initialState = localStorage.getItem("productDetails")
  ? JSON.parse(localStorage.getItem("productDetails"))
  : {
      ProductList: [],
    };

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState,
  reducers: {
    productDetailsSuccess: (state, action) => {
      state.ProductList = action.payload.ProductList || [];

      localStorage.setItem("productDetails", JSON.stringify(action.payload));
    },
    productDetailsLogoutSuccess: (state, action) => {
      state.ProductList = null;
      localStorage.removeItem("productDetails");
    },
  },
});

export const { productDetailsSuccess, productDetailsLogoutSuccess } =
  productDetailsSlice.actions;

export const selectname = (state) => state.productDetails.name;
export const selectProductList = (state) => state.productDetails.ProductList;
export default productDetailsSlice.reducer;
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = localStorage.getItem("productDetails")
//   ? JSON.parse(localStorage.getItem("productDetails"))
//   : {
//       productList: [],
//     };

// const productDetailsSlice = createSlice({
//   name: "productDetails",
//   initialState,
//   reducers: {
//     productDetailsSuccess: (state, action) => {
//       state.productList = action.payload.productList || [];
//       localStorage.setItem("productDetails", JSON.stringify(action.payload));
//     },
//     productDetailsLogoutSuccess: (state, action) => {
//       state.productList = [];
//       localStorage.removeItem("productDetails");
//     },
//     removeProduct: (state, action) => {
//       state.productList = state.productList.filter(
//         (product, index) => index !== action.payload
//       );
//       localStorage.setItem("productDetails", JSON.stringify(state));
//     },
//   },
// });

// export const {
//   productDetailsSuccess,
//   productDetailsLogoutSuccess,
//   removeProduct,
// } = productDetailsSlice.actions;

// export const selectProductList = (state) => state.productDetails.productList;

// export default productDetailsSlice.reducer;
