import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import "./imageCard.css";
const videoImageCard = (props) => {
  return (
    <div className="Media-Card-Container">
      <img
        src={props.url}
        alt="Display "
        style={{
          objectFit: "cover",
          display: "inline-flex",
        }}
      />
    </div>
  );
};

export default videoImageCard;
