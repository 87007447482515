import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import userReducer from "../slices/user/userSlice";
import productDetailsReducer from "../Slices/productCartSlices";

export default configureStore({
  reducer: {
    productDetails: productDetailsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  // devTools: false,
});
