import React from "react";

function Footer() {
  return (
    <div className=" mp-auto bg-white h-12 flex justify-center items-center">
      <div className="flex items-center">
        <p className="text-[#AB2921] min-[250px]:text-[7px] min-[350px]:text-sm">
          Copyright © 2021 Fetch. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
