import * as base from "../settings";

export const listProducttUrl = base.BASE_URL + "v1/web/list-product/";
export const ProductCategoryUrl = base.BASE_URL + "v1/products/list-category/";
export const ProductGroupUrl = base.BASE_URL + "v1/products/list-group/";
export const ProductGroupImageUrl = base.BASE_URL + "v1/web/list-group_image/";
export const SubGroupsUrl = base.BASE_URL + "v1/products/list-subtag/";
export const SizesUrl = base.BASE_URL + "v1/products/list-sub_variant/";
export const FilterUrl = base.BASE_URL + "v1/products/filter-product/";
export const CaresolUrl = base.BASE_URL + "v1/main/list-banner/";
export const StockListUrl = base.BASE_URL + "v1/web/stock-product/";
// export const AdminLogin = "http://192.168.1.72:8003/api/v1/web/admin-login/"
// export const AdminData = "http://192.168.1.72:8003/api/v1/web/activity-log/"
export const AdminLogin = base.BASE_URL + "v1/web/admin-login/";
export const AdminData = base.BASE_URL + "v1/web/activity-log/";
