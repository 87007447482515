import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";

import SingleProductCart from "../../components/SingleProductCart";
import WatsappButton from "../../components/WatsappButton";
import { StockListUrl } from "../../api/FilterApis";
import { selectProductList } from "../../Slices/productCartSlices";
import store from "../../Store/store";
import { productDetailsSuccess } from "../../Slices/productCartSlices";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
function Cart({ handleCartOpen }) {
  const Is482px = useMediaQuery({ query: "(max-width: 482px)" });
  const id = useSelector((state) => state.productDetails);
  const dispatch = useDispatch();
  const productList = selectProductList(store.getState());
  console.log(id, "ProductList");
  console.log(productList, "codessssssssss");
  const [t] = useTranslation("common");
  const [stock, setStock] = useState([]);
  const [opacity, setopacity] = useState(1);
  const [outofstock, setOutofstock] = useState({
    is_outofstock: false,
    id_list: [],
  });
  // const Quantity
  const fetchDataGet = async () => {
    let matchingObj = [];
    id.ProductList.map((item, index) => {
      let newitem = item.data.ProductVariants.find(
        (obj) =>
          obj.VariantName === item.ProductColor &&
          obj.ProductVariantName === item.Size
      );
      matchingObj = [...matchingObj, newitem];
    });
    const ids = matchingObj.map((item) => item.id);
    console.log(ids, "StocklistStocklistStocklist");
    console.log(matchingObj, "outofstockoutofstockoutofstock");
    // const Stocklist = id.ProductList.map((product) => product.id);
    let response = await fetch(StockListUrl, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ id: ids }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.StatusCode === 6000) {
          const filtered_list = result.data.filter(
            (obj) => obj.Stock === "0.00000000"
          );
          const id_list = filtered_list.map((obj) => obj.id);
          console.log(result.data, "dataaaaaaaa=>");
          setStock(result.data);
          setOutofstock({ ...outofstock, id_list: id_list });
        } else {
          setStock([]);
        }
      });
  };
  const stockCheck = () => {
    console.log(stock.every((item) => parseFloat(item.Stock) > 0));
    console.log(stock, "stock");
    return stock.every((item) => parseFloat(item.Stock) > 0);
  };
  const handleChanngeQty = () => {};
  const handleSendClick = () => {
    if (id?.ProductList?.length > 0) {
      if (stockCheck()) {
        let string = "";
        // console.log(string, ">>>>>>>>>>>>>>>");
        const phoneNumber = "+97433146112"; // Replace with your phone number
        const message = id.ProductList.map((item, index) => {
          let listofProducts =
            index +
            1 +
            ")" +
            " productCode: " +
            item.data.ProductCode +
            "%0a" +
            "   Variant: " +
            item.ProductColor +
            "%0a" +
            "   SubVarinat: " +
            item.Size +
            "%0a" +
            "   Quantity: " +
            item.Quantity +
            "%0a" +
            "   Link: " +
            item.ProductLink +
            "%0a";
          string = string + "%0a" + listofProducts;
        });

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=
      ${
        // encodeURIComponent(
        // JSON.stringify(
        string
        // )
        // )
      }`;

        // Open the user's WhatsApp app and pre-fill a message to be sent to your phone number
        window.open(whatsappUrl);
      } else {
        setOutofstock({ ...outofstock, is_outofstock: true });
        swal("Product is  Out-of-Stock in Cart");
      }
    } else {
      swal("Their is no Product in cart");
    }
  };
  const handleChangeInCart = (e, v, name, index, Quantity) => {
    console.log(v);
    console.log(e);
    console.log(name);
    console.log(index);
    console.log(Quantity);
    const productList = selectProductList(store.getState());
    const productIndexToUpdate = index; // Replace with the index of the item you want to update
    const productToUpdate = productList[productIndexToUpdate];
    let updatedProduct = {
      ...productToUpdate,
    };
    console.log(productIndexToUpdate);
    console.log(productToUpdate);
    console.log(updatedProduct);
    if (name === "color") {
      updatedProduct = {
        ...productToUpdate,
        ProductColor: v.VariantName,
        /* update the properties of the object as needed */
      };
    }
    if (name === "size") {
      updatedProduct = {
        ...productToUpdate,
        Size: v,
        /* update the properties of the object as needed */
      };
    }
    if (name === "-") {
      if (updatedProduct.Quantity > 1) {
        updatedProduct = {
          ...productToUpdate,
          Quantity: Quantity - 1,
          /* update the properties of the object as needed */
        };
      }
    }
    if (name === "+") {
      let stockCheck = productList[productIndexToUpdate];
      let stockCheckObj = stockCheck.data.ProductVariants.find(
        (obj) =>
          obj.VariantName === stockCheck.ProductColor &&
          obj.ProductVariantName === stockCheck.Size
      );
      let stock = Number(stockCheckObj.Stock).toFixed(0);
      stock = Number(stock);
      console.log(typeof stock, "productToUpdate");

      console.log(typeof Quantity, "productToUpdate");
      if (Quantity < stock) {
        updatedProduct = {
          ...productToUpdate,
          Quantity: Quantity + 1,
          /* update the properties of the object as needed */
        };
      }
    }
    // if (name === "delete") {
    //   const updatedProductList = [...productList];
    //   updatedProductList.splice(index, 1); // remove the item at the given index
    //   dispatch(removeProduct(updatedProductList));
    // }

    const updatedProductList = [...productList];
    updatedProductList[productIndexToUpdate] = updatedProduct;

    store.dispatch(
      productDetailsSuccess({
        ProductList: updatedProductList,
      })
    );
  };
  useEffect(() => {
    fetchDataGet();
  }, [dispatch, id]);
  useEffect(() => {
    // Reset the value of id when state.productDetails changes
    dispatch({ type: "RESET_ID" });
  }, [dispatch, id]); // The effect depends on dispatch and id

  console.log(stock, "86555555555555555486666666666666");
  return (
    <div className="bg-white overflow-auto w-full md:h-[calc(100vh-6rem)] lg:h-[calc(100vh-6rem)] sm:h-[calc(100vh-10rem)] h-[calc(100vh-9.1rem)] border-gray-100 ">
      <div className="flex justify-between px-4 bg-white py-2">
        <p className="flex justify-left font-semibold text-2xl leading-9 font-poppins tracking-tighter text-[#B13011] ">
          {t("Your Cart")}
        </p>
        <button
          onClick={() => {
            handleCartOpen(false);
          }}
        >
          {!Is482px ? (
            <CloseIcon />
          ) : (
            <p
              className="text-[#B13011]
          "
            >
              go back&gt;
            </p>
          )}
        </button>
      </div>

      <div className="mx-2 mb-[9.5rem]">
        {id.ProductList.map((item, index) => (
          <SingleProductCart
            Quantity={Number(item.Quantity)}
            ProductColor={item.ProductColor}
            ProductCode={item.data.ProductCode}
            ProductName={item.data.ProductName}
            item={item}
            SellingPrice={item.data.SellingPrice}
            SubVariants={item.data.SubVariants}
            Size={item.Size}
            index={index}
            stock={stock}
            handleChangeInCart={handleChangeInCart}
            outofstock={outofstock}
            fetchDataGet={fetchDataGet}
          />
        ))}
      </div>
      <div className=" flex bg-[#097B4A] rounded-md fixed mx-1 p-[10px] bottom-[0.9rem] md:bottom-[1.1rem] sm:bottom-[1.5rem] min-w-[272px] w-[94%] min-[428px]:mx-2  min-[428px]:w-[415px]">
        <WatsappButton
          message={t("Buy Now On Whatsapp")}
          handleSendClick={handleSendClick}
        />
      </div>
    </div>
  );
}

export default Cart;
