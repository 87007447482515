import React, { useState } from 'react'
import image1 from "../../components/images/AboutImages/Top up 1242 x 2208 3 .png"
import image2 from "../../components/images/AboutImages/Top up 1242 x 22084.png"
import image3 from "../../components/images/AboutImages/Top up 1242 x 2208.png"
// import image4 from "../../components/images/AboutImages/fd08da82c0c169fa6d0f5a4f847f6fb8181b5db2.png"
import Slider from 'react-slick'

const About = () => {
    const imae = [image3, image2, image1]

    const settings = {
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 1000,
        cssEase: "linear",
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <>
            <div className='my-7 px-20 flex justify-center'>
                <div className='flex flex-col md:flex-row items-centers justify-center'>

                    <div className='w-60 md:w-96 '>
                        <Slider {...settings} className='drop-shadow-2xl rounded-md'>
                            {imae.map((i, index) => (
                                <img
                                    className='rounded-xl'
                                    key={index}
                                    src={i}
                                    alt="Display"
                                />
                            ))}
                        </Slider>
                    </div>
                    <div className='flex items-center flex-col justify-center px-10 md:mt-0 mt-7'>

                        <h3 className='font-bold text-5xl mb-16'>Detail Description</h3>
                        <p className='text-left'>Top Up online is an online  platform designed for businesses to manage their online store and transactions. The app allows the admin user to monitor and control various aspects of the e-commerce platform, including product management, order fulfillment, payment processing, and customer service,enabling the admin user to monitor and control various aspects of the e-commerce platfor</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About