import React, { useState } from "react";
import watsappbutton from "./images/whatsapp.svg";

function WatsappButton({ handleSendClick, message }) {
  const [state, setState] = useState({
    list: [
      {
        ProductCode: "561",
        Variant: "red",
        SubVarinat: "10",
        Qty: "5",
        link: "",
      },
      {
        ProductCode: "256",
        Variant: "black",
        SubVarinat: "8",
        Qty: "2",
      },
    ],
    string: [],
  });

  return (
    <button
      className="grow flex"
      onClick={() => {
        handleSendClick();
      }}
    >
      <img src={watsappbutton} alt="" className="grow-0" />
      <span className="text-white grow leading-6 font-poppins">
        {message}
      </span>
    </button>
  );
}

export default WatsappButton;
