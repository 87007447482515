import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import React, { useCallback, useEffect, useState } from "react";
import { AdminData, AdminLogin } from "../../api/FilterApis";

const today = new Date();

const date = today
  .toLocaleString("en-us", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
  .replace(/(\d+)\/(\d+)\/(\d+)/, "$3-$1-$2");

const VikncodesAdmin = () => {
  const isLogedin = localStorage.getItem("Admin");

  const [isAdmin, setIsAdmin] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [loading, setLoading] = useState(true);
  const [logState, setLogState] = useState(false);
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setpassword] = useState("");
  const [type, setType] = useState("All");
  const [datefrom, setDatefrom] = useState(date);
  const [dateTo, setDateTo] = useState(date);
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);

  const submitLogin = () => {
    setLoading(false);
    fetch(AdminLogin, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: password,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.StatusCode === 6000) {
          localStorage.setItem("Admin", result.token);
          const isLogedin = localStorage.getItem("Admin");
          console.log(isLogedin);
          isLogedin && setIsAdmin(true);
        } else {
          console.log("not login");
          setLogState(true);
          setLoading(true);
        }
      });
  };

  const handlepage = (event, value) => {
    setPage(value);
  };

  const convertIntoTime = (time) => {
    const event = new Date(`2022 ${time}`);
    console.log(event.toLocaleTimeString());
    return event.toLocaleTimeString();
  };

  const callTheApi = () => {
    // console.log(page, "inside the api");
    fetch(AdminData, {
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        token: isLogedin,
        page_no: page,
        items_per_page: 10,
        search: searchText,
        filter: type,
        FromDate: datefrom,
        ToDate: dateTo,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.StatusCode === 6000) {
          setIsAdmin(true);
          setRowData(result.data);
        } else {
          setIsAdmin(false);
        }
      });
  };

  useEffect(() => {
    callTheApi();
  }, [page]);

  useEffect(() => {
    isLogedin && setIsAdmin(true);
    callTheApi();
    // callTheApi
    // (() =>
    //   callTheApi()
    // )();
    // localStorage.removeItem('Admin');
    // setIsAdmin(false);
  }, []);

  return isAdmin ? (
    <>
      <Box marginTop={"2rem"} marginBottom={"2rem"}>
        <div className="flex">
          <div className="mr-[1rem]">
            <label
              htmlFor="first_name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
            >
              Type
            </label>
            <FormControl
              sx={{ minWidth: 80, backgroundColor: "white", width: "160px" }}
              size="small"
            >
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                defaultValue={"All"}
                // value={age}
                onChange={(e) => setType(e.target.value)}
                autoWidth
                // label="Age"
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Information"}>Information</MenuItem>
                <MenuItem value={"Warning"}>Warning</MenuItem>
                <MenuItem value={"Error"}>Error</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="mr-[1rem]">
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
            >
              Date from
            </label>
            <input
              type="date"
              id="last_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Doe"
              required=""
              value={datefrom}
              onChange={(e) => setDatefrom(e.target.value)}
            />
          </div>
          <div className="mr-[1rem]">
            <label
              htmlFor="company"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
            >
              Date to
            </label>
            <input
              type="date"
              id="company"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required=""
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
          </div>
          <div className="mr-[1rem]">
            <label
              htmlFor="company"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white text-left"
            >
              Search
            </label>
            <div class="flex items-center">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="p-2.5 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={() => callTheApi()}
              >
                <svg
                  className="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </div>
      </Box>

      <Box marginBottom={"1rem"} height={"100vh"}>
        {rowData.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      {/* time */}
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      User
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Source
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Action
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }} align="right">
                      Message
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: "bold" }}
                      align="right"
                    ></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rowData.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography> {row.date}</Typography>{" "}
                        <Typography>{convertIntoTime(row.time)}</Typography>
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">{row.username}</TableCell>
                      <TableCell align="right">{row.source}</TableCell>
                      <TableCell align="right">{row.action}</TableCell>
                      <TableCell align="right">
                        {row.message.length > 100
                          ? row.message.slice(1, 50) + " ...."
                          : row.message}
                      </TableCell>

                      <TableCell align="center">
                        {" "}
                        <RemoveRedEyeIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSingleData(row);
                            setOpen(true);
                          }}
                        />{" "}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack marginTop={"1rem"} spacing={2}>
              <Pagination
                sx={{ display: "flex", justifyContent: "center" }}
                page={page}
                onChange={handlepage}
                count={10}
                color="error"
              />
            </Stack>{" "}
          </>
        ) : (
          <Typography>You have no data for this day</Typography>
        )}
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Description"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {singleData.description}
          </DialogContentText>
        </DialogContent>
        <DialogTitle id="alert-dialog-title">{"Body parser"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {singleData.body_params || "null"}
          </DialogContentText>
        </DialogContent>
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {singleData.message}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <Box
      height={"90vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Paper
        component="form"
        variant="outlined"
        elevation="24"
        shape="rounded"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
          display: "flex",
          flexDirection: "column",
          padding: "50px",
          width: "30rem",
          borderRadius: "15px",
          // backgroundColor: 'white'
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Username"
          // type="text"
          variant="outlined"
          error={logState}
          helperText={logState && "Incorrect entry."}
          style={{ width: "100%" }}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          id="outlined-password-input"
          label="Password"
          // type="Password"
          variant="outlined"
          error={logState}
          helperText={logState && "Incorrect entry."}
          style={{ width: "100%" }}
          onChange={(e) => setpassword(e.target.value)}
        />

        <Button
          variant="contained"
          color="error"
          onClick={() => submitLogin()}
          style={{ width: "100%" }}
        >
          {loading ? (
            "Login"
          ) : (
            <CircularProgress
              style={{ color: "white", height: "30px", width: "30px" }}
            />
          )}
        </Button>
      </Paper>
    </Box>
  );
};

export default VikncodesAdmin;
